<template>
  <AffiliateLayout>
    <b-card no-body>
      <b-card-body>
        <b-tabs>
          <b-tab
            title="Rank"
            active
          >
            <div
              class="d-flex flex-wrap justify-content-between align-items-center w-100"
              style="gap: 8px;"
            >
              <div>&nbsp;</div>
              <b-button
                v-b-modal.modal-rank
                variant="primary"
                type="button"
                class="ml-2"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                {{ $t('Create new') }}
              </b-button>
            </div>
            <vue-good-table
              ref="promotion-products"
              class="custom-good-table mt-2"
              style-class="vgt-table"
              :columns="rankFields"
              :rows="rankItems"
              :pagination-options="{
                enabled: true,
                perPage:pageLength
              }"
            >
              <!-- Table: Head -->
              <template
                slot="table-column"
                slot-scope="props"
              >
                <span>
                  {{ $t(props.column.label) }}
                </span>
              </template>

              <!-- Table: Rows -->
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Rank -->
                <span
                  v-if="props.column.field === 'title'"
                  class="d-flex flex-wrap align-items-center"
                >
                  <span
                    class="d-block line-clamp"
                    style="max-width: 320px;"
                    :title="props.row.title"
                  >
                    {{ props.row.title }}
                  </span>
                </span>

                <!-- Column: Description -->
                <span
                  v-else-if="props.column.field === 'description'"
                  class="d-flex flex-wrap align-items-center"
                >
                  <span
                    class="d-block line-clamp"
                    style="max-width: 320px;"
                    :title="props.row.description"
                  >
                    {{ props.row.description }}
                  </span>
                </span>

                <!-- Column: Actions -->
                <span
                  v-else-if="props.column.field === 'actions'"
                  class="d-inline-flex text-center"
                >
                  <b-button
                    variant="flat-dark"
                    class="btn-icon rounded-circle"
                    :to="{ path: '/affiliate/rank-segment/edit' }"
                  >
                    <feather-icon icon="Edit3Icon" />
                  </b-button>
                  <b-button
                    variant="flat-dark"
                    class="btn-icon rounded-circle"
                    @click="handlerDelete(props.row.id)"
                  >
                    <feather-icon icon="EyeOffIcon" />
                  </b-button>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap p-2">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                      Showing 1 to
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['3','5','10']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap"> of {{ props.total }} entries </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-tab>
          <b-tab
            title="Segment"
          >
            <div
              class="d-flex flex-wrap justify-content-between align-items-center w-100"
              style="gap: 8px;"
            >
              <div>&nbsp;</div>
              <b-button
                variant="primary"
                type="button"
                class="ml-2"
                to="/bundle/add"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                {{ $t('Create new') }}
              </b-button>
            </div>
            <vue-good-table
              ref="promotion-products"
              class="custom-good-table mt-2"
              style-class="vgt-table"
              :columns="rankFields"
              :rows="rankItems"
              :pagination-options="{
                enabled: true,
                perPage:pageLength
              }"
            >
              <!-- Table: Head -->
              <template
                slot="table-column"
                slot-scope="props"
              >
                <span>
                  {{ $t(props.column.label) }}
                </span>
              </template>

              <!-- Table: Rows -->
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Rank -->
                <span
                  v-if="props.column.field === 'title'"
                  class="d-flex flex-wrap align-items-center"
                >
                  <span
                    class="d-block line-clamp"
                    style="max-width: 320px;"
                    :title="props.row.title"
                  >
                    {{ props.row.title }}
                  </span>
                </span>

                <!-- Column: Description -->
                <span
                  v-else-if="props.column.field === 'description'"
                  class="d-flex flex-wrap align-items-center"
                >
                  <span
                    class="d-block line-clamp"
                    style="max-width: 320px;"
                    :title="props.row.description"
                  >
                    {{ props.row.description }}
                  </span>
                </span>

                <!-- Column: Actions -->
                <span
                  v-else-if="props.column.field === 'actions'"
                  class="d-inline-flex text-center"
                >
                  <b-button
                    variant="flat-dark"
                    class="btn-icon rounded-circle"
                  >
                    <feather-icon icon="Edit3Icon" />
                  </b-button>
                  <b-button
                    v-b-modal.modal-segment
                    variant="flat-danger"
                    class="text-danger btn-icon rounded-circle"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap p-2">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                      Showing 1 to
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['3','5','10']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap"> of {{ props.total }} entries </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-tab>
        </b-tabs>
        <!-- table -->
      </b-card-body>
    </b-card>

    <!-- CREATE RANK MODAL -->
    <b-modal
      id="modal-rank"
      size="sm"
      centered
      title="Tạo rank"
      header-bg-variant="primary"
      header-text-variant="light"
      header-close-variant="danger"
      title-tag="h4"
      ok-title="Tạo"
      ok-variant="primary"
      cancel-title="Hủy"
      cancel-variant="light"
    >
      <b-form-group
        label="Tên hạng"
        label-for="rank-name"
      >
        <b-form-input
          id="rank-name"
          placeholder="Nhập tên hạng"
        />
      </b-form-group>
      <b-form-group
        label="Điều kiện"
        label-for="rank-condition"
      >
        <b-form-textarea
          id="rank-condition"
          placeholder="Điều kiện thăng hạng"
          rows="5"
        />
      </b-form-group>
    </b-modal>

    <!-- DELETE SEGMENT MODAL -->
    <b-modal
      id="modal-segment"
      size="sm"
      centered
      title="Xóa nhóm"
      header-bg-variant="danger"
      header-text-variant="light"
      header-close-variant="danger"
      title-tag="h4"
      ok-title="Xóa"
      ok-variant="danger"
      cancel-title="Hủy"
      cancel-variant="light"
    >
      <div class="d-flex flex-column text-center">
        <div class="mb-2">
          <feather-icon
            icon="AlertTriangleIcon"
            size="80"
            class="text-danger"
          />
        </div>
        <div class="mb-2">
          <strong>Bạn cần phải xóa các chương trình đang hoạt động dưới đây trước khi xóa nhóm 1 </strong>
        </div>
        <div class="mb-2">
          <ol class="rs-list">
            <li class="text-danger">
              Sale abc
            </li>
            <li class="text-danger">
              Sale XYZ
            </li>
          </ol>
        </div>
      </div>
    </b-modal>
  </AffiliateLayout>
</template>

<script>
import {
  // BAvatar,
  BFormSelect,
  BPagination,
  // BBadge,
  // BDropdownItem,
  // BDropdown,
  // BRow,
  // BCol,
  BModal,
  BTabs,
  BTab,
  BCard,
  // BCardHeader,
  BCardBody,
  BFormInput,
  BFormTextarea,
  BButton,
  BFormGroup,
} from 'bootstrap-vue'
import general from '@/mixins/index'
import { VueGoodTable } from 'vue-good-table'
// import vSelect from 'vue-select'
import AffiliateLayout from '../layouts/AffiliateLayout.vue'

export default {
  name: 'RankSegmentList',
  components: {
    VueGoodTable,
    // BAvatar,
    BFormSelect,
    BPagination,
    // BBadge,
    // BDropdownItem,
    // BDropdown,
    // BRow,
    // BCol,
    BModal,
    BTabs,
    BTab,
    BCard,
    // BCardHeader,
    BCardBody,
    BFormInput,
    BFormTextarea,
    BButton,
    BFormGroup,
    // vSelect,
    AffiliateLayout,
  },
  mixins: [general],

  props: {},

  data() {
    return {
      rankFields: [
        {
          field: 'title', label: 'Rank', sortable: false, thClass: 'text-left',
        },
        {
          field: 'content', label: 'Condition', sortable: false, thClass: 'text-left', tdClass: 'text-left',
        },
        {
          field: 'actions', label: 'Actions', sortable: false, tdClass: 'text-center',
        },
      ],
      rankItems: [
        {
          id: 1,
          title: 'Đồng',
          content: 'Doanh số đạt 20.000.000 VNĐ + 10 Referrals',
        },
        {
          id: 2,
          title: 'Bạc',
          content: 'Doanh số đạt 10.000.000 VNĐ + 10 Referrals',
        },
        {
          id: 3,
          title: 'Vàng',
          content: 'Doanh số đạt 10.000.000 VNĐ',
        },
      ],
      segmentFields: [
        {
          field: 'title', label: 'Group', sortable: false, thClass: 'text-left',
        },
        {
          field: 'content', label: 'Condition', sortable: false, thClass: 'text-left', tdClass: 'text-left',
        },
        {
          field: 'actions', label: 'Actions', sortable: false, tdClass: 'text-center',
        },
      ],
      segmentItems: [
        {
          id: 1,
          title: 'Nhóm 1',
          content: 'Doanh số đạt 20.000.000 VNĐ + 10 Referrals',
        },
        {
          id: 2,
          title: 'Nhóm 2',
          content: 'Doanh số đạt 10.000.000 VNĐ + 10 Referrals',
        },
        {
          id: 3,
          title: 'Nhóm 3',
          content: 'Doanh số đạt 10.000.000 VNĐ',
        },
      ],
      pageLength: 10,
      filterSelected: 'rank',
      filterTabs: [
        {
          id: 'rank',
          label: 'Rank',
        },
        {
          id: 'segment',
          label: 'Segment',
        },
      ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        pending     : 'light-warning',
        apply       : 'light-success',
        finished    : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    filterItems() {
      if (this.discountTypeSelected === null) return this.items
      return this.items.filter(item => item.id === this.discountTypeSelected)
    },
  },
  methods: {},
}
</script>

<style lang="sass" scoped>
.filter
  &-tab
    display: flex
    overflow: hidden
    flex: none
    border-bottom: 1px solid #e5e5e5

    &__item
      min-width: 64px
      padding: 0 16px
      height: 56px
      display: flex
      align-items: center
      font-weight: 500
      font-size: 14px
      border-bottom: 3px solid transparent
      cursor: pointer
      transition: all .2s

      &.active,
      &:hover
        color: var(--primary)

      &.active
        border-bottom-color: var(--primary)
</style>
